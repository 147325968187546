'use strict';

angular.module('enervexSalesappApp').controller('TemplateCtrl', function($scope, Template, SpecificationTemplate, $stateParams, Flash, _) {
	$scope.errors = {};
	if ($stateParams.id == "new") {
		$scope.template = {}
	} else {
		fetchTemplate();
	}
	fetchMasterTemplate();
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			var payload = _.clone($scope.template);
			payload = _.omit(payload, "__v");
			Flash.clear();
			if (!paylod._id) {
				Template.save(_.extend(payload, {template: $scope.masterTemplate._id})).$promise.then(function(result){
					$scope.success = true;
					$scope.template = result;
					Flash.create('success', '<strong>Success!</strong> Successfully saved template.', 0, {}, true);
				}).catch(function(err){
					$scope.errors.other = 'Problem saving';
					$scope.message = '';
				})
			} else {
				Template.update({id:payload._id}, payload).$promise.then(function(){
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully saved proeprty.', 0, {}, true);
				}).catch(function(err){
					$scope.errors.other = 'Problem saving';
					$scope.message = '';
				})
			}
		}
	};

	$scope.tinymceOptions = {
		plugins: 'link image code',
		toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
	};

	function fetchTemplate() {
		Template.get({
			id: $stateParams.id
		}).$promise.then(function(template) {
			$scope.template = template;
		})
	};

	function fetchMasterTemplate() {
		SpecificationTemplate.query().$promise.then(function(specTemplates) {
			$scope.masterTemplate = specTemplates[0];
		})
	}
});
